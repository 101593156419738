import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { transpose2 } from "../utils";

function MarkersTableComponent({ clusteringData, selectedHeatmapAssay, maxWidth }) {
  const { heatmapMarkers, markers } = selectedHeatmapAssay === null 
    ? clusteringData 
    : clusteringData[selectedHeatmapAssay];
  
  const [data, setData] = useState(null);
  const [initialRowsState, setInitialRowsState] = useState(null);

  const getUniqueMarkers = (allMarkers) => {
    const hash = Object.create(null);
    return allMarkers.reduce((accumulator, row) => {
      const { gene, "pct.1": pct1, "pct.2": pct2 } = row;
      if (hash[gene]==null) {
        hash[gene] = accumulator.push(row) - 1;
      } else if (pct1 - pct2 > accumulator[hash[gene]]["pct.1"] - accumulator[hash[gene]]["pct.2"]) {
        accumulator[hash[gene]] = row;
      }
      return accumulator;
    }, []);
  }

  const getHeatmapRows = (initialData) => {
    const filteredMarkers = initialData.rows.filter((row) => heatmapMarkers.includes(row.gene));
    const uniqueMarkers = getUniqueMarkers(filteredMarkers);
    uniqueMarkers.sort((a, b) => heatmapMarkers.indexOf(a.gene) - heatmapMarkers.indexOf(b.gene));
    return { ...initialData, rows: uniqueMarkers };
  };

  const getAllMarkers = () => setData(prevData => ({ ...prevData, rows: initialRowsState }));

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      <Button onClick={() => setData(getHeatmapRows(data))}>Heatmap Markers</Button>
      <Button onClick={getAllMarkers}>All Markers</Button>
    </GridToolbarContainer>
  );

  useEffect(() => {
    const headers = Object.keys(markers).filter(m => m !== "gene");
    const columns = headers.map((header) => ({
      field: header,
      headerName: header.toUpperCase(),
      type: ["CLUSTER", "GENE_NAME", "TYPE"].includes(header.toUpperCase()) ? "string" : "number",
      width: ["CLUSTER", "GENE_NAME"].includes(header.toUpperCase()) ? 120 : 70
    }));

    columns.unshift({ field: "gene", headerName: "Feature", width: 250 });

    const columnsValues = columns.map(c => markers[c.field]);
    const rowsValues = transpose2(columnsValues);

    const rows = rowsValues.map((row, index) => {
      const result = { id: index, gene: row[0] };
      headers.forEach((header, index) => result[header] = row[index + 1]);
      return result;
    });

    setInitialRowsState(rows);
    const initialData = { columns, rows };
    setData(getHeatmapRows(initialData));
  }, [selectedHeatmapAssay]);

  return (
    <>
      {data && heatmapMarkers && (
        <DataGrid
          sx={{ width: maxWidth }}
          slots={{ toolbar: CustomToolbar }}
          {...data}
          pagination={{ paginationModel: { pageSize: 25 } }}
        />
      )}
    </>
  );
}

export default MarkersTableComponent;
