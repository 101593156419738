import React, { useState } from "react";
import {
    DataGrid
  } from "@mui/x-data-grid";


export function FeatureTable({features, assay, maxWidth}) {

    const columns = [
        {
            field: "collection",
            headerName: "Collection",
            type: "string",
            width: 200
        },
        {
            field: "geneset",
            headerName: "Geneset",
            type: "string",
            width: 300
        }      
    ];
    const rows = Object.entries(features).reduce((p,[k,v]) => p.concat(v.map( (cv,i)=>({id:p.length+i,collection:k, geneset:cv}))), []);
    const data = {
        columns: columns,
        rows: rows
    }
    return (
        <DataGrid
        sx={{ width: maxWidth }}
        // slots={{ toolbar: CustomToolbar }}
        {...data}
        pagination={{
          paginationModel: {
            pageSize: 25,
          },
        }}
      />
    );
}
