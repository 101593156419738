// export function transpose(matrix) {
//   return matrix.reduce(
//     (prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])),
//     []
//   );
// }
// export function transpose2(matrix) {
//   return matrix.reduce(
//     (prev, next) => Object.values(next).map((item, i) => (prev[i] || []).concat(item)),
//     []
//   );
// }


export function transpose(matrix) {
  return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

export function transpose2(matrix) {
  return Object.keys(matrix[0]).map(i => matrix.map(row => row[i]));
}
